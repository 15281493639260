/*****************************************************************************************
*** APP
*****************************************************************************************/

#root, .App {
  height: 100%;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-size: 16px;
}

/*****************************************************************************************
*** App layout 
*****************************************************************************************/

.page {
  margin-top: 20px;
  margin-bottom: 50px;
  padding-top: 5px;
  padding-bottom: 50px;
}

.app-header {
  text-align: center;
  height: 60px;
}

#loader, #loader > div {
  height: 100%;
}

#loader > div img {
  width: 200px;
  margin-left: -1rem;
  margin-bottom: 1rem;
}

#loader > div .ui.loader {
  position: relative;
  top: 0;
  left: 25%;
  transform: none;
}


/*****************************************************************************************
*** App navigation 
*****************************************************************************************/

.navigation .ui.inverted.menu, .ui.menu {
  box-shadow: 3px 3px 13px #959595;
}

.navigation .ui.menu .item::before,
.navigation .ui.menu .header.item::before {
  background: #fff;
}

.navigation .mobile {
  height: 60px;
}

.navigation .logo {
  margin-right: 50px;
}

.menu .navlink, .menu .item a, .ui.menu .ui.dropdown .menu .item a {
  color: rgba(0,0,0,.87) !important;
  font-size: 15px;
}

.menu .navlink:hover, .menu > .item:hover {
  background: rgba(0,0,0,.05) !important;
}
.menu .navlink:hover, .menu > .item:hover a {
  color: #21ba45;
  background: rgba(0,0,0,0) !important;
}

.ui.menu .ui.dropdown .menu > .item:hover {
  background: rgba(0,0,0,.05) !important;
  color: #21ba45 !important;
}

.ui.menu .ui.dropdown .menu .item:hover a {
  color: #21ba45 !important;
}

.ui.menu .ui.dropdown .menu>.item:first-child {
  margin-top: 8px;
}


.activeLink {
  font-weight: bold;
  padding-top: 6px;
  padding-bottom: 4px;
  border-bottom: 1px solid #f72;
}

.logged-user .text, .menu-dropdown .text {
  padding-right: 5px
}

.mobile-navigation {
  height: calc(100% - 80px);
}

.mobile-navigation .ui.segment.pushable {
  border: 0;
  border-radius: 0;
}

/* DO NOT REMOVE THESE OR THE MOBILE NAVIGATION BREAKS */
.pushable:not(body).patch { 
  -webkit-transform: unset; transform: unset;
}

.pushable:not(body) .patch.ui.sidebar {
  position: fixed;
}

body > div {
  z-index: 100;
}


/* Messages component */
.message-container {
  margin: 20px 10px 20px 10px;
}

/*
.message-container {
  position: relative;
}
.messages {
  margin: 0;
  padding: 0;
}
.messages.active {
  position: absolute;
  z-index: 1000;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  margin: 0;
  border-bottom: 1px solid #e2e2e2;
}*/


/*****************************************************************************************
*** App pages 
*****************************************************************************************/

.pagenotfound, .forbidden {
  text-align: center;
}

.pagenotfound p, .forbidden p {
  font-size: 72px;
  font-weight: bold;
}

.ui.list.landing-list .item {
  margin-bottom: 1.3em;
}


/*****************************************************************************************
*** App content styles 
*****************************************************************************************/

p {
  font-size: 18px;
}

a {
  font-size: 18px;
}

hr {
  height: 3px;
  color: rgba(34,36,38,.15);
  background-color: rgba(34,36,38,.15);
  border: rgba(34,36,38,.15);
}

hr.title {
  margin-bottom: 20px;
}

a.green, .green {
  color: #21ba45;
}

a.orange, .orange {
  color: #f72;
}

.form .field {
  font-size: 18px;
}


/*****************************************************************************************
*** App components
*****************************************************************************************/

/* Buttons */

/*.ui.button, .ui.fluid.button {
    margin: 1px 5px 1px 0px !important;
}*/

.icon-button {
  background: #fff;
  border: 1px solid #999;
  padding: 10px;
}

.ui.labeled.icon.button, .ui.labeled.icon.buttons .button, .ui.icon.button {
  margin-bottom: 1px;
  margin-top: 2px;
}

.description {
  text-align: center;
  margin-bottom: 30px;
  font-size: 18px;
}
.description.highlight {
  color: red;
}

.ui.label.help-label {
  margin-bottom: 20px;
  background-color: #efefef;
  padding: 1em;
}

.box-container {
  margin-bottom: 20px;
}
.box {
  /*border: 1px solid rgba(34,36,38,.1);*/
  border-bottom: 1px solid rgba(34,36,38,.2);
  padding: 20px;
  /*border-radius: 5px;
  box-shadow: 2px 5px 5px rgba(34,36,38,.1);
  margin-bottom: 10px;*/
}
.box:first-child {
  border-top: 1px solid rgba(34,36,38,.2);
}

.box span.number {
  font-size: 20px;
  font-weight: bold;
}

.section {
  margin-top: 40px;
  margin-bottom: 60px;
}
.section h2 {
  margin-bottom: 20px;
}
.section .grid {
  margin-bottom: 20px;
}

.choose-location {
  margin-bottom: 20px;
}


/* Checkbox */
.ui.form .chemicals-container .disabled.field,
.ui.form .chemicals-container .disabled.field label {
  opacity: 1;
  color: #000;
}

/* Loader */
#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(230,230,230,0.85);
  z-index: 2;
  cursor: pointer;
}

.ui.dimmer {
  background-color: rgba(230,230,230,0.85);
  margin-top: -20px;
  height: calc(100% + 20px);
}

.ui.dimmer .ui.loader:before {
  border-color: #f72;
}

.ui.dimmer .ui.loader {
  color: #000;
}


/* Grid columns without padding */
.ui.grid.nopadding .row { margin: 0 1rem; }
.ui.grid.nopadding .row .column { padding: 0; }


/* TT WizardMenu and Wizard Item */
.tt-nav {
  margin-bottom: 40px;
}
.tt-nav .row .column.title, .tt-nav .title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.1rem;
}
.tt-nav .title hr {
  padding: 1px;
}
.tt-nav .title hr.orange  {
  background-color: #f72;
}
.ui.grid>.row.info-container {
  padding-top: 5px;
  padding-bottom: 5px;
}

/* TT Page Title */
.tt-nav .title h1 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5rem;
}

/* First level accordion */
.inspection .ui.accordion {
  /*border: solid 3px #e2e2e2;*/
  border-radius: 10px;
  margin-bottom: 30px;
}

/* first level accordion title */
.inspection .ui.accordion .title {
  border-top: solid 1px #ddd;
  padding: 20px;
  background-color: #fff;
}

/* first level accordion title first item */
.inspection .ui.accordion .title:first-child {
  border-top: none;
}

/* first level accordion active title*/
.inspection .ui.accordion .title.active {
  background-color: #eee;
  border-bottom: solid 3px #21ba45;
  border-radius: 10px 10px 0 0;
}

.inspection .ui.accordion .content.active + .title {
  border-top: none;
}

/* first level accordion title container h3*/
.inspection .ui.accordion .title h3 {
  margin: 0;
}

/* First level accordion content */
.inspection .ui.accordion .title ~ .content:not(.ui) {
  padding: 20px;
  /*border-bottom: 1px solid #ddd;*/
  background-color: #f7f7f7;
  border-radius: 0 0 10px 10px;
}

/* First level accordion content last item */
.inspection .ui.accordion .title ~ .content:not(.ui):last-child {
  padding-bottom: 20px;
}

/* Second level accordion */
.inspection .ui.accordion .accordion {
  margin-top: 0;
}

/* Second level title*/
.inspection .ui.accordion .accordion .title {
  border: 1px solid #f7f7f7;
  background: #f7f7f7;
}

/* Second level active title*/
.inspection .ui.accordion .accordion .title.active {
  border: 1px solid #fff;
  background-color: #fff;
}

/* Second level content */
.inspection .ui.accordion .accordion .title ~ .content:not(.ui) {
  padding: 0 20px 20px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 0 0 10px 10px;
}

/* Second level content last item*/
.inspection .ui.accordion .accordion .title ~ .content:not(.ui):last-child {
  margin-bottom: 10px;
}

/* Evaluate risk factors form*/
.inspection .ui.container.evaluation-container {
  margin-top: 40px;
  margin-bottom: 60px;
}

.inspection .ui.container.evaluation-container p{
  margin-top: 30px;
  margin-bottom: 30px;
}

.tables {
  overflow-x: scroll;
}

.button-container, .create-inspection.ui.button.green {
  margin-top: 20px;
}

.ui.button.basic.link, .ui.button.basic.link:hover, .ui.button.basic.link:focus {
  box-shadow: 0 0 !important;
}