.table-wrapper td::first-letter,
.table-wrapper .button::first-letter {
    text-transform: uppercase;
}

.ui.table thead th.sortable-table {
    cursor: pointer;
}

.pagination-container {
    width: 100%;
    margin: 1rem 0 0;
    text-align: center;
}
.pagination-container .top {
    margin: 0 0 1rem 0;
    padding-top: 1rem;
    border-top: 1px solid #eee;
}

.table-wrapper .collapsable td:first-child {
    cursor: pointer;
    vertical-align: top;
    font-size: 16px;
    width: 38px;
    color: #2DA96B;
}

.table-wrapper .collapsed td {
    white-space: nowrap;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-wrapper .open .header {
    font-weight: bold;
}

.table-wrapper .open div {
    margin-bottom: 0.5rem;
}    

.table-wrapper .open div :last-child {
    margin-bottom: 0;
}

#root .table-wrapper .management .ui.button {
    margin: 1px 5px 1px 0px;
}
